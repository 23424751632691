import React, { PureComponent } from 'react'
import qs from 'qs'
import { get } from 'utils/axios'
import Page from 'src/pages/freelancers/detail'

const pageConf = {
  context: 'freelancers-detail',
  app: 'freelancers',
  title: 'freelancersDetail',
  description: 'freelancersDetail',
}

interface IProps {
  query: {
    shortId: string
  }
}

export default class FreelancerDetail extends PureComponent<IProps> {
  static async getInitialProps({ query }) {
    if (!query.shortId) {
      return { notFound: true }
    }

    const nextQuery = qs.stringify(query, { addQueryPrefix: true })
    const { data } = await get(`api/profile/detail/${query.shortId}${nextQuery}`)

    if (!data.profile || (!data.profile.public && data.profile.status === 'banned')) {
      return { notFound: true }
    }

    return {
      profile: data.profile,
      pageConf,
      query,
    }
  }

  render() {
    return <Page {...this.props} />
  }
}
