import React from 'react'
import LanguageIcon from '@material-ui/icons/Language'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import InstagramIcon from '@material-ui/icons/Instagram'
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball'
import BrushIcon from '@material-ui/icons/Brush'
import GitHubIcon from '@material-ui/icons/GitHub'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import TwitterIcon from '@material-ui/icons/Twitter'
import YouTubeIcon from '@material-ui/icons/YouTube'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import MusicVideoIcon from '@material-ui/icons/MusicNote'
import FacebookIcon from '@material-ui/icons/Facebook'
import FreelancerUtils from 'utils/freelancer'
import { SocialUrls } from './SocialInputs'

type IUrls = { [key: string]: any } & SocialUrls

interface IProps {
  urls: IUrls
  withBr?: boolean
}

const SocialLinks: React.FC<IProps> = ({ urls, withBr }) => {
  const hasLinks = FreelancerUtils.hasSocialLinks(urls)

  if (!hasLinks) {
    return null
  }

  return (
    <>
      {withBr && <br />}
      {urls.web && (
        <p className="paragraph with-icon paragraph--small paragraph--bold no-margin-bottom">
          <LanguageIcon />{' '}
          <a
            className="link--black"
            href={urls.web}
            target="_blank"
            rel="noopener"
            data-cy="freelancer-web"
          >
            Web
          </a>
        </p>
      )}

      {urls.linkedin && (
        <p className="paragraph with-icon paragraph--small paragraph--bold no-margin-bottom">
          <LinkedInIcon />{' '}
          <a
            className="link--black"
            href={urls.linkedin}
            target="_blank"
            rel="noopener"
            data-cy="freelancer-linkedin"
          >
            LinkedIn
          </a>
        </p>
      )}

      {urls.instagram && (
        <p className="paragraph with-icon paragraph--small paragraph--bold no-margin-bottom">
          <InstagramIcon />{' '}
          <a
            className="link--black"
            href={urls.instagram}
            target="_blank"
            rel="noopener"
            data-cy="freelancer-instagram"
          >
            Instagram
          </a>
        </p>
      )}

      {urls.dribble && (
        <p className="paragraph with-icon paragraph--small paragraph--bold no-margin-bottom">
          <SportsBasketballIcon />{' '}
          <a
            className="link--black"
            href={urls.dribble}
            target="_blank"
            rel="noopener"
            data-cy="freelancer-dribble"
          >
            Dribble
          </a>
        </p>
      )}

      {urls.behance && (
        <p className="paragraph with-icon paragraph--small paragraph--bold no-margin-bottom">
          <BrushIcon />{' '}
          <a
            className="link--black"
            href={urls.behance}
            target="_blank"
            rel="noopener"
            data-cy="freelancer-behance"
          >
            Behance
          </a>
        </p>
      )}

      {urls.github && (
        <p className="paragraph with-icon paragraph--small paragraph--bold no-margin-bottom">
          <GitHubIcon />{' '}
          <a
            className="link--black"
            href={urls.github}
            target="_blank"
            rel="noopener"
            data-cy="freelancer-github"
          >
            Github
          </a>
        </p>
      )}

      {urls.gitlab && (
        <p className="paragraph with-icon paragraph--small paragraph--bold no-margin-bottom">
          <AccountTreeIcon />{' '}
          <a
            className="link--black"
            href={urls.gitlab}
            target="_blank"
            rel="noopener"
            data-cy="freelancer-gitlab"
          >
            Gitlab
          </a>
        </p>
      )}

      {urls.twitter && (
        <p className="paragraph with-icon paragraph--small paragraph--bold no-margin-bottom">
          <TwitterIcon />{' '}
          <a
            className="link--black"
            href={urls.twitter}
            target="_blank"
            rel="noopener"
            data-cy="freelancer-twitter"
          >
            Twitter
          </a>
        </p>
      )}

      {urls.youtube && (
        <p className="paragraph with-icon paragraph--small paragraph--bold no-margin-bottom">
          <YouTubeIcon />{' '}
          <a
            className="link--black"
            href={urls.youtube}
            target="_blank"
            rel="noopener"
            data-cy="freelancer-youtube"
          >
            Youtube
          </a>
        </p>
      )}

      {urls.vimeo && (
        <p className="paragraph with-icon paragraph--small paragraph--bold no-margin-bottom">
          <OndemandVideoIcon />{' '}
          <a
            className="link--black"
            href={urls.vimeo}
            target="_blank"
            rel="noopener"
            data-cy="freelancer-vimeo"
          >
            Vimeo
          </a>
        </p>
      )}

      {urls.tiktok && (
        <p className="paragraph with-icon paragraph--small paragraph--bold no-margin-bottom">
          <MusicVideoIcon />{' '}
          <a
            className="link--black"
            href={urls.tiktok}
            target="_blank"
            rel="noopener"
            data-cy="freelancer-tiktok"
          >
            TikTok
          </a>
        </p>
      )}

      {urls.facebook && (
        <p className="paragraph with-icon paragraph--small paragraph--bold no-margin-bottom">
          <FacebookIcon />{' '}
          <a
            className="link--black"
            href={urls.facebook}
            target="_blank"
            rel="noopener"
            data-cy="freelancer-facebook"
          >
            Facebook
          </a>
        </p>
      )}
    </>
  )
}

export default SocialLinks
