import React, { FC } from 'react'
import dynamic from 'next/dynamic'

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false })

type Props = {
  url: string
}

const VideoPlayer: FC<Props> = ({ url }) => {
  if (url.length === 0) {
    return null
  }

  return (
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        url={url}
        width="100%"
        height="100%"
        controls
        config={{
          facebook: {
            attributes: {
              'data-width': '640px',
              'data-height': '360px',
            },
          },
        }}
      />
    </div>
  )
}

export default VideoPlayer
