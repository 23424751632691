import React, { FC } from 'react'
import Input from 'components/form/Input'
import useTranslation from 'translations/hooks/useTranslation'

interface IProps {
  value: string
  name: string
  label?: string
  error?: boolean
  placeholder?: string
  errorText?: string
  tooltip?: string
  type?: string
  onChange: (e) => void
}

const EmailInput: FC<IProps> = ({
  label,
  name,
  value,
  error,
  placeholder,
  errorText,
  type,
  tooltip,
  onChange,
}) => {
  const { t } = useTranslation()
  const handleInput = (e) => {
    e.target.value = e.target.value.toLowerCase()
    onChange(e)
  }

  return (
    <Input
      label={label || 'E-mail'}
      name={name}
      placeholder={placeholder || `${t('common.shortcuts.forExample')} pre@pretlak.sk`}
      value={value}
      onChange={handleInput}
      error={error}
      errorText={errorText || t('common.error.invalidEmail')}
      tooltip={tooltip}
      type={type || 'text'}
      required
    />
  )
}

export default EmailInput
