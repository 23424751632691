import React, { FC } from 'react'
import classnames from 'classnames'

interface IProps {
  dark?: boolean
}

const renderPill = (dark: boolean) => {
  return (
    <span
      className={classnames('loader__pill', {
        'loader__item-dark-gray': dark,
      })}
    />
  )
}

const LoaderText = ({ dark, longer }: { dark?: boolean; longer?: boolean }) => (
  <span
    className={classnames('loader__text', {
      'loader__text-dark-gray': dark,
      'loader__text--longer': longer,
    })}
  />
)

const FreelancerLoader: FC<IProps> = ({ dark }) => {
  return (
    <div className="container job-container">
      <a className="job job--level-2 job--loading">
        <div className="job__content-left">
          <h1 className="job__title">
            <LoaderText dark={dark} />
          </h1>
          <h2 className="job__subtitle">
            <LoaderText dark={dark} longer />
          </h2>
          <div className="pills-wrapper">
            {renderPill(dark)}
            {renderPill(dark)}
            {renderPill(dark)}
          </div>
        </div>

        <div className="job__content-right">
          <p className="job__info">
            <LoaderText dark={dark} />
          </p>
          <p className="job__info">
            <LoaderText dark={dark} />
          </p>
        </div>

        <div className="job__content-date">
          <p className="job__info job__info--light">
            {renderPill(dark)}
            {renderPill(dark)}
          </p>
        </div>
      </a>
    </div>
  )
}

export default FreelancerLoader
