import React, { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import Tippy from '@tippyjs/react'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import { useRouter } from 'next/router'
import useTranslation from 'translations/hooks/useTranslation'
import { toggleCompanyFavouriteFreelancers } from 'redux/managers/company'
import { toggleProfileFavouriteFreelancers } from 'redux/managers/profile'
import TrackingUtils from 'utils/tracking'
import { IProfile } from 'redux/interfaces/profile'
import RouterUtils from 'utils/router'
import type { IStoreState } from 'redux/reducers'

interface IProps {
  profile: IProfile
  className?: string
  fixedSize?: boolean
  defaultStyle?: 'white' | 'gray'
}

const FreelancerFavourite: FC<IProps> = ({
  profile,
  className,
  fixedSize,
  defaultStyle = 'white',
}) => {
  const router = useRouter()
  const { loggedCompany, loggedProfile } = useSelector((state: IStoreState) => {
    return { loggedCompany: state.company, loggedProfile: state.profile }
  })
  const [isClient, setIsClient] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isFavourite, setIsFavourite] = useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isNotLogged = !loggedCompany && !loggedProfile

  useEffect(() => {
    setIsClient(true)
  }, [])

  useEffect(() => {
    let nextIsFavorite = false

    if (loggedCompany) {
      nextIsFavorite = Boolean(
        loggedCompany?.favouriteFreelancers?.find((f) => {
          return f._id === profile._id
        })
      )
    } else if (loggedProfile) {
      nextIsFavorite = Boolean(
        loggedProfile?.favouriteFreelancers?.find((f) => {
          return f._id === profile._id
        })
      )
    }

    setIsFavourite(nextIsFavorite)
  }, [loggedCompany, loggedProfile])

  const handleFavoriteClick = async (e: React.MouseEvent, freelancerId: string) => {
    e.preventDefault()

    if (loading) {
      return
    }

    if (isNotLogged) {
      RouterUtils.push(`/login?next=${router.asPath}`)
      return
    }

    setLoading(true)

    if (loggedCompany) {
      await dispatch(
        toggleCompanyFavouriteFreelancers({
          shortId: loggedCompany.shortId,
          freelancerId,
        })
      )
    } else if (loggedProfile) {
      await dispatch(
        toggleProfileFavouriteFreelancers({
          shortId: loggedProfile.shortId,
          freelancerId,
        })
      )
    }

    TrackingUtils.event('toggle-favourite-freelancer')
    setLoading(false)
  }

  const getTooltipContent = () => {
    return t(isFavourite ? 'freelancers.removeFavourite' : 'freelancers.addFavourite')
  }

  if (isClient) {
    return (
      <div className={classnames('job__content-action', className)}>
        <Tippy
          touch={false}
          content={getTooltipContent()}
          animation={false}
          className={classnames('tippy-custom')}
        >
          <span
            className={classnames('button-icon button-icon--green', {
              'button-icon--active': isFavourite,
              'button-icon--fixed-size': fixedSize,
              'button-icon--default-style-white': defaultStyle === 'white',
              'button-icon--default-style-gray': defaultStyle === 'gray',
            })}
            onClick={(e) => handleFavoriteClick(e, profile._id)}
          >
            <FavoriteBorderIcon className="icon-border" />
          </span>
        </Tippy>
      </div>
    )
  }

  return null
}

export default FreelancerFavourite
