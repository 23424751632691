import React, { PureComponent } from 'react'

interface IProps {
  visible: boolean
  title: string
  description: React.ReactNode | string
  onClose: (e) => any
  children?: any
}

export default class FullSizeModal extends PureComponent<IProps> {
  componentDidMount() {
    if (this.props.visible) {
      document.body.classList.add('modal-visible')
      document.addEventListener('keydown', this.handleEscClose, false)
    }
  }

  handleEscClose = (e) => {
    if (e.keyCode === 27) {
      this.props.onClose(e)
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.visible !== this.props.visible) {
      if (this.props.visible) {
        document.body.classList.add('modal-visible')
        document.addEventListener('keydown', this.handleEscClose, false)
      } else {
        document.body.classList.remove('modal-visible')
        document.removeEventListener('keydown', this.handleEscClose, false)
      }
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('modal-visible')
    document.removeEventListener('keydown', this.handleEscClose, false)
  }

  close = (e) => {
    e.preventDefault()
    this.props.onClose(e)
  }

  render() {
    const { title, description, children } = this.props
    return (
      <div className="modal">
        <div className="modal__content">
          <a
            onClick={this.close}
            className="modal__header-close modal__header-close--absolute"
          >
            <span className="button__in">
              <span className="button__in-icon">+</span>
            </span>
          </a>

          <div className="container container--small modal-container-top">
            <div className="margin-bottom">
              <h4 className="heading-1 center">{title}</h4>
              <p className="paragraph center">{description}</p>
            </div>
            {children}
          </div>
        </div>
      </div>
    )
  }
}
