import { IProfile } from 'redux/interfaces/profile'

interface IProps {
  profile: IProfile
}

const ProfilePicture = ({ profile }: IProps) => {
  if (profile.picture) {
    return (
      <>
        <div className="job-detail__header-left-logo">
          <div className="job-detail__logo-wrapper">
            <img
              className="job-detail__logo job-detail__logo--rounded"
              src={`${profile.picture}_360x360`}
              alt={`${profile.name}`}
            />
          </div>
        </div>
      </>
    )
  }

  return null
}

export default ProfilePicture
