import React, { ChangeEvent, KeyboardEvent, FocusEvent, FC, useState } from 'react'
import classnames from 'classnames'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import Tooltip from 'components/Tooltip'
import useTranslation from 'translations/hooks/useTranslation'

interface IProps {
  label?: string
  labelExtra?: React.ReactNode
  name: string
  placeholder: string
  value: any
  type?: string
  required?: boolean
  focus?: boolean
  disabled?: boolean
  error?: boolean
  errorText?: string
  tooltip?: string
  note?: string
  salary?: boolean
  withoutMargin?: boolean
  readOnly?: boolean
  pattern?: string
  maxLength?: number
  autoComplete?: string
  withoutNumbers?: boolean
  visibilityToggle?: boolean
  currency?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void
  lengthCounter?: boolean
}

const Input: FC<IProps> = ({
  label,
  labelExtra,
  focus,
  disabled,
  required,
  type,
  withoutMargin,
  tooltip,
  name,
  salary,
  placeholder,
  value,
  note,
  error,
  errorText,
  pattern,
  maxLength,
  readOnly,
  onKeyPress,
  onFocus,
  onBlur,
  visibilityToggle,
  autoComplete,
  currency,
  withoutNumbers,
  onChange,
  lengthCounter,
}) => {
  const [contentVisible, setContentVisible] = useState<boolean>(false)
  const { t } = useTranslation()

  const handleChange = (e) => {
    if (withoutNumbers && /\d/.test(e.target.value)) {
      return
    }

    onChange(e)
  }

  const toggleVisibility = (e) => {
    e.preventDefault()
    setContentVisible((prevVisible) => !prevVisible)
  }

  return (
    <div
      className={classnames('input-wrapper', {
        'margin-top-small': !withoutMargin,
        'input-wrapper--error': error,
      })}
    >
      {label && (
        <label className="label">
          {label}&nbsp;
          {required && <span className="paragraph--orange">*</span>}
          <Tooltip content={tooltip} />
          {labelExtra}
          {visibilityToggle && (
            <a
              className="link link--black input__visibility-toggle"
              onClick={toggleVisibility}
            >
              {contentVisible ? (
                <>
                  <VisibilityOffIcon />
                  {t('common.actions.hide')}
                </>
              ) : (
                <>
                  <VisibilityIcon />
                  {t('common.actions.show')}
                </>
              )}
            </a>
          )}
        </label>
      )}

      {!label && salary && (
        <label className="label label--salary">{currency === 'CZK' ? 'Kč' : '€'}</label>
      )}

      <input
        className={classnames('input', {
          'input--error': error,
          'input--salary': salary,
          'input--length-counter': maxLength && lengthCounter,
        })}
        autoFocus={focus}
        readOnly={readOnly}
        type={contentVisible ? 'text' : type || 'text'}
        name={name}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        maxLength={maxLength}
        pattern={pattern}
        autoComplete={autoComplete}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
      />

      {error && !salary && (
        <p className="input__error-text input__error-text--visible">
          {errorText || t('common.requiredField')}
        </p>
      )}

      {maxLength && lengthCounter && !error && (
        <p
          className={classnames('input__length-counter', {
            'input__length-counter--error': maxLength - value.length < 0,
          })}
        >
          {maxLength - value.length}
        </p>
      )}

      {note && (
        <p className="paragraph paragraph--small margin-top-super-small">
          <i>{note}</i>
        </p>
      )}
    </div>
  )
}

export default Input
