import Link from 'next/link'
import useTranslation from 'translations/hooks/useTranslation'
import { IProfile } from 'redux/interfaces/profile'
import Emoji from 'components/Emoji'

interface IProps {
  profile: IProfile
}

export default function ProFreelancer({ profile }: IProps) {
  const { t } = useTranslation()

  return (
    <Link
      href="/freelancers/pro"
      className="upsell-small upsell-small--block upsell-small--clickable upsel-small--gray margin-top-small no-margin-bottom"
    >
      <p className="paragraph paragraph--small no-margin-bottom">
        <strong>{profile.name}</strong>{' '}
        <span className="pill pill--super-small pill--position">
          <Emoji>{t('freelancers.pro.label')}</Emoji>
        </span>
        <strong> freelancer!</strong>
      </p>
      <p className="paragraph paragraph--small no-margin-bottom">
        {t('freelancers.upsell.detail.note')}
        <br />

        <span className="link link--black">{t('freelancers.upsell.detail.cta')}</span>
      </p>
    </Link>
  )
}
