import React, { PureComponent } from 'react'
import classnames from 'classnames'
import Emoji from 'components/Emoji'

interface IProps {
  emoji?: string
  text: string | any
  persistent?: boolean
  error?: boolean
  modal?: boolean
  onHide?: () => void
}

interface IState {
  visible: boolean
}

export default class Notification extends PureComponent<IProps, IState> {
  state: IState = {
    visible: true,
  }

  componentDidMount() {
    this.hide()
  }

  hide = () => {
    if (!this.props.persistent) {
      setTimeout(() => {
        this.setState({ visible: false })
        this.props.onHide?.()
      }, 4000)
    }
  }

  render() {
    if (!this.state.visible) {
      return null
    }

    const { emoji, text, error, modal } = this.props

    return (
      <div
        className={classnames('notification', {
          'notification--error': error,
          'notification--in-modal': modal,
        })}
      >
        <p className="paragraph paragraph--small">
          {emoji && <Emoji className="notification__emoji">{emoji} </Emoji>}
          {text}
        </p>
      </div>
    )
  }
}
