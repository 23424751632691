import React, { FC } from 'react'
import { IProfile } from 'redux/interfaces/profile'
import useTranslation from 'translations/hooks/useTranslation'
import Emoji from 'components/Emoji'
import FreelancerUtils from 'utils/freelancer'
import RouterUtils from 'utils/router'
import FreelancerFavourite from './FreelancerFavourite'

type IProps = {
  profile: IProfile
  addToQuery?: (key: string, value: string) => void
}

const FreelancerPills: FC<IProps> = ({ profile, addToQuery }) => {
  const { t } = useTranslation()

  const onTagClick = (e: React.MouseEvent, propName: string) => {
    e.preventDefault()

    if (addToQuery) {
      return addToQuery(propName, 'true')
    }

    return RouterUtils.push(`/freelancers?${propName}=true`)
  }

  return (
    <Emoji>
      {FreelancerUtils.isPremium(profile) && (
        <span
          className="pill pill--super-small"
          onClick={(e) => {
            onTagClick(e, 'pro')
          }}
        >
          <Emoji tag="div">{t('freelancers.pro.label')}</Emoji>
        </span>
      )}
      {profile.available && (
        <span
          className="pill pill--green pill--super-small"
          onClick={(e) => {
            onTagClick(e, 'available')
          }}
        >
          {t('label.available')}
        </span>
      )}
      {profile.addons?.topSponsoredStart && (
        <span className="pill pill--white pill--super-small">
          {t('label.sponsoredFreelancer')}
        </span>
      )}
      <FreelancerFavourite profile={profile} className="freelancer__favourite-mobile" />
    </Emoji>
  )
}

export default FreelancerPills
